import { apiClient } from '../../infrastructure/api/apiClient';

export const getTasks = () => {
  return apiClient.get('/task/assigned-tasks').then(response => {
    return response.data;
  });
};

export const getTaskDetails = (id: any) => {
  return apiClient.get(`/task/details/${id}`).then(response => {
    return response.data;
  });
};

export const updateTaskStatus = (id?: string, status?: string) => {
  return apiClient
    .put(`/task/${id}/status`, { status: status })
    .then(response => {
      return response.status;
    });
};

export const updateTaskAsset = (id?: string, assetId?: string) => {
  return apiClient
    .put(`/task/${id}/asset`, { assetId: assetId })
    .then(response => {
      return response.status;
    });
};
export default getTasks;
