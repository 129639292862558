import styled from 'styled-components';

const Button = styled.div<{ bgColor?: string; txtColor?: string }>`
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 0;
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
  line-height: 1.125rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  color: ${props => (props.txtColor ? props.txtColor : '#46484a')};
`;

interface Props {
  text: string;
  textColor?: string;
  onClick?: React.MouseEventHandler;
}

const LinkButton: React.FC<Props> = ({ text, textColor, onClick }) => (
  <>
    <Button onClick={onClick} txtColor={textColor}>
      {text}
    </Button>
  </>
);

export default LinkButton;
