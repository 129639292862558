import styled from 'styled-components';

const ColoredBox = styled.div<ColorProps>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  background-color: ${props => props.color};
  margin-right: 10px;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxWrapper = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 400;
`;

interface ColorProps {
  color: string;
}

const BoxText = styled.div`
  padding-top: 7px;
  color: #424042;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 1px;
  cursor: pointer;
`;

interface InputTextProps {
  symbol: string;
  text: string;
  color: string;
  onClick?: React.MouseEventHandler;
}

const IconTextComponent: React.FC<InputTextProps> = ({
  symbol,
  text,
  color,
  onClick,
}) => (
  <>
    <BoxWrapper>
      <ColoredBox color={color}>{symbol}</ColoredBox>
      <BoxText onClick={onClick}>{text}</BoxText>
    </BoxWrapper>
  </>
);

export default IconTextComponent;
