/* eslint-disable prettier/prettier */
import * as React from 'react';
import styled from 'styled-components';
import AccountLayout from '../../../layouts/dashboard/accountLayout';
import InputComponent from '../../../components/Input/Input.component';
import Button from '../../../components/Button/Button.component';
import { changePassword } from '../../../services/auth/auth.service';
import {
  InfoMessageComponent,
  MessageTypes,
} from '../../../components/InfoMessage/infoMessage.component';
import { PasswordStrengthStatuses } from '../../../components/PasswordStrength/PasswordStrength.components';
import PasswordStrengthComponent from '../../../components/PasswordStrength/PasswordStrength.components';

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const ActionsForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 15px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 250px;
`;

const UserSecurityPage: React.FunctionComponent = () => {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isRepeatPasswordSame, setisRepeatPasswordSame] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [passwordStrengthStatus, setPasswordStrengthStatus] =
    React.useState<PasswordStrengthStatuses>();

  const handleCurrentPasswordChange = (value: string) => {
    setCurrentPassword(value);
    hideErrorMessage();
  };

  const handleNewPasswordChange = (value: string) => {
    setNewPassword(value);
    hideErrorMessage();
  };

  const handleRepeatPasswordChange = (value: string) => {
    setConfirmPassword(value);
    setisRepeatPasswordSame(value === newPassword);
    hideErrorMessage();
  };

  const handleChangePassword = async () => {
    hideErrorMessage();
    hideSuccessMessage();

    if (!currentPassword || !newPassword || !confirmPassword) {
      showErrorMessage('All fields is required!');
      return;
    }

    if (!isRepeatPasswordSame) {
      showErrorMessage('Password does not match!');
      return;
    }

    if (passwordStrengthStatus !== PasswordStrengthStatuses.STRONG) {
      showErrorMessage('You does not set a strong password!');
      return;
    }

    await changePassword(currentPassword, newPassword)
      .then(() => setShowSuccess(true))
      .catch(() => showErrorMessage('Password update failed!'));
  };

  const showErrorMessage = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const hideErrorMessage = () => {
    setErrorMessage('');
    setShowError(false);
  };

  const hideSuccessMessage = () => {
    setShowSuccess(false);
  };

  const handlePasswordStrengthCheck = async (
    status: PasswordStrengthStatuses,
  ) => {
    setPasswordStrengthStatus(status);
  };

  return (
    <>
      <AccountLayout>
        <Wrapper>
          <h3>Change Password</h3>
          <FormItem>
            <InputComponent
              label="Current password"
              type="password"
              onChildStateChange={handleCurrentPasswordChange}
            />
          </FormItem>
          <FormItem>
            <InputComponent
              label="New password"
              type="password"
              onChildStateChange={handleNewPasswordChange}
            />
          </FormItem>
          <FormItem>
            {newPassword ? (
              <PasswordStrengthComponent
                password={newPassword}
                onScoringFinished={handlePasswordStrengthCheck}
              />
            ) : null}
          </FormItem>
          <FormItem>
            <InputComponent
              label="Confirm password"
              type="password"
              onChildStateChange={handleRepeatPasswordChange}
            />
          </FormItem>
          <FormItem>
            {showError ? (
              <InfoMessageComponent
                text={errorMessage}
                type={MessageTypes.Error}
              />
            ) : null}
            {showSuccess ? (
              <InfoMessageComponent
                text="Password was updated successfully"
                type={MessageTypes.Success}
              />
            ) : null}
          </FormItem>
          <ActionsForm>
            <Button
              text="Update password"
              textColor="#fff"
              onClick={handleChangePassword}
            />
          </ActionsForm>
        </Wrapper>
      </AccountLayout>
    </>
  );
};

export default UserSecurityPage;
