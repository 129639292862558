/* eslint-disable prettier/prettier */
import { apiClient } from '../../infrastructure/api/apiClient';

export const register = (username: string, email: string, password: string) => {
  return apiClient.post('/auth', {
    username,
    email,
    password,
  });
};

export const checkAuth = async () => {
  return await apiClient.get('/auth/me').then(response => {
    return response;
  });
};

export const login = (username: string, password: string) => {
  return apiClient
    .post('/auth/login', {
      email: username,
      password,
    })
    .then(response => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch(e => console.error(e));
};

export const logout = () => {
  localStorage.removeItem('user');
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const resetPassword = async (email: string, app: string) => {
  return await apiClient
    .post('/auth/password/reset', {
      email: email,
      app: app,
    })
    .then(response => {
      return response;
    });
};

export const changePassword = async (
  currentPassword: string,
  newPassword: string,
) => {
  return await apiClient
    .post('/user/change-password', {
      currentPassword,
      newPassword,
    })
    .then(response => {
      return response;
    });
};

export default getCurrentUser;
