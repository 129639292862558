import React, { useState } from 'react';
import styled from 'styled-components';

const DropdownSelectBoxWrapper = styled.div`
  height: 30px;
  font-size: 14px;
  border: 1px solid #ebebeb;
  padding-left: 10px;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;

const FaAngleUp = styled.li`
  list-style-type: none;
`;
const FaAngleDown = styled.li`
  list-style-type: none;
`;
const SelectedOption = styled.div`
  padding-top: 7px;
`;

const Options = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #ebebeb;
  margin-left: -11px;
  padding-top: 7px;
  /* width: 70%; */
  margin-top: 6px;
  z-index: 999;
`;

const ResultWrapper = styled.div`
  white-space: nowrap;
  overflow-y: auto;
  max-height: 200px;
`;

const Option = styled.div`
  padding: 15px;
  &:hover {
    background: #ebebeb;
    cursor: pointer;
  }
`;

const SearchBox = styled.div`
  padding: 10px;
`;

const SearchInput = styled.input`
  border: 1px solid #ebebeb;
  /* width: 200px; */
  border-radius: 7px;
  padding: 10px;
  &:focus {
    outline: none;
  }
`;

const Label = styled.div`
  font-size: 12px;
  color: #515251;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export interface IOption {
  value: number | string;
  label: string;
}

interface DropdownSelectBoxProps {
  options: IOption[] | undefined;
  selectedOption?: IOption;
  label?: string;
  onChange: (option: any) => void;
}

export const DropdownSelectBox: React.FC<DropdownSelectBoxProps> = ({
  options,
  selectedOption,
  label,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const filteredOptions = options?.filter(option =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleOptionClick = (option: any) => {
    onChange(option);
    setIsOpen(false);
    setSearchQuery('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setIsOpen(true);
  };

  return (
    <>
      <Label>{label}</Label>
      <DropdownSelectBoxWrapper>
        <SelectedOption onClick={() => setIsOpen(!isOpen)}>
          {selectedOption?.label}
          {isOpen ? <FaAngleUp></FaAngleUp> : <FaAngleDown></FaAngleDown>}
        </SelectedOption>
        {isOpen && (
          <Options>
            {options?.length && options.length > 4 ? (
              <SearchBox>
                <SearchInput
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
              </SearchBox>
            ) : null}
            <ResultWrapper>
              {filteredOptions?.map(option => (
                <Option
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </Option>
              ))}
            </ResultWrapper>
          </Options>
        )}
      </DropdownSelectBoxWrapper>
    </>
  );
};

export default DropdownSelectBox;
