import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../../layouts/dashboard/dashboard.layout';
import { useState } from 'react';
import ITask from '../../../types/task/task.type';
import {
  getTaskDetails,
  updateTaskAsset,
  updateTaskStatus,
} from '@rocket-desert/common-lib';
import './styles/taskDetails.style.css';
import {
  assigneAssetToEmployee,
  getAssetsCatalog,
} from '../../../services/catalog.service';

import LinkButton from '../../../components/LinkButton/LinkButton.component';
import { convertDate } from '../../../utils/date-format.utility';
import Button from '../../../components/Button/Button.component';
import {
  DropdownSelectBox,
  IOption,
} from '../../../components/DropDownSelectBox/DropDownSelectBox.component';
import styled from 'styled-components';

const StatusDropDownBlock = styled.div`
  width: 200px;
  margin-top: 25px;
`;

const TaskStatusSelectOptions: IOption[] = [
  { value: 'TODO', label: 'Todo' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'COMPLETED', label: 'Completed' },
];

function TaskDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedTaskStatus, setSelectedTaskStatus] = useState<IOption>();
  const [selectedAsset, setSelecteAssetd] = useState<IOption>();
  const [task, setTasks] = useState<ITask>();
  const [assetOptions, setAssetOptions] = useState<IOption[]>();
  const [isAssetAssignRequired, setIsAssetAssignRequired] =
    useState<boolean>(false);

  React.useEffect(() => {
    if (!task) {
      return;
    }
    const fetchTasks = async () => {
      const assets = await getAssetsCatalog();

      const assetOptions = assets
        ? assets.map((item: any) => {
            return {
              value: item.id,
              label: `${item.name} (${item.code})`,
            };
          })
        : [];
      setAssetOptions(assetOptions);

      setSelecteAssetd(
        task?.asset
          ? {
              value: task?.asset.id,
              label: `${task?.asset.name} (${task?.asset.code})`,
            }
          : assetOptions[0],
      );
    };
    fetchTasks().then(r => console.log(r));
  }, [task]);

  React.useEffect(() => {
    const fetchTasks = async () => {
      const taskDetails = await getTaskDetails(id);
      setTasks(taskDetails);
      setSelectedTaskStatus(
        TaskStatusSelectOptions.find(p => p.value === taskDetails.taskStatus),
      );
    };
    fetchTasks();
  }, [id]);

  const handleOptionChange = (option: any) => {
    setSelecteAssetd(option);
  };

  const handleStatusChange = (option: any): void => {
    setSelectedTaskStatus(option);
    setIsAssetAssignRequired(
      option.value === 'COMPLETED' && task?.actionType === 'HARDWARE_SOFTWARE',
    );
  };

  const updateTask = async () => {
    if (task?.employee) {
      if (isAssetAssignRequired) {
        await assigneAssetToEmployee({
          userId: task?.employee.id,
          assetsId: selectedAsset?.value,
        });
        await updateTaskAsset(id, selectedAsset?.value.toString());
      }
      await updateTaskStatus(id, selectedTaskStatus?.value.toString());
      navigate('/tasks');
    }
  };

  return (
    <DashboardLayout>
      <div className="card">
        <div className="card-body">
          <div className="block-col block-col-m-0">
            <div className="block-item">
              <h3>Title</h3>
              <div>{task?.title}</div>
            </div>
          </div>

          <div className="block-col">
            <div className="block-item">
              <h3>Task Type</h3>
              <div>{task?.taskType}</div>
            </div>
          </div>

          <div className="block-col">
            <div className="block-item">
              <h3>Description</h3>
              <div>{task?.title}</div>
            </div>
          </div>

          <div className="block-row">
            <div className="block-item">
              <h3>Full name</h3>
              <div>
                {task?.employee?.firstName} {task?.employee?.lastName}
              </div>
            </div>
            <div className="block-item">
              <h3>Position</h3>
              <div>{task?.employee?.position}</div>
            </div>
            <div className="block-item">
              <h3>Team</h3>
              <div>{task?.employee?.team ? task?.employee?.team : '-'}</div>
            </div>
          </div>
          <div className="block-row">
            <div className="block-item">
              <h3>Start date</h3>
              <div>{task ? convertDate(task.startDate) : null}</div>
            </div>
            <div className="block-item">
              <h3>Due date</h3>
              <div>{task ? convertDate(task.dueDate) : null}</div>
            </div>
          </div>
          <StatusDropDownBlock>
            <h3>Status</h3>
            <DropdownSelectBox
              options={TaskStatusSelectOptions}
              selectedOption={selectedTaskStatus}
              onChange={handleStatusChange}
            />
          </StatusDropDownBlock>
          {task?.asset ? (
            <div className="block-col">
              <div className="block-item">
                <h3>Assigned asset</h3>
                <div>
                  {task?.asset.name} ({task?.asset.code})
                </div>
              </div>
            </div>
          ) : null}
          {isAssetAssignRequired ? (
            <>
              <div className="block-col">
                <div>
                  <div>
                    <h3>Please choose asset to assign</h3>
                  </div>
                </div>
                <div>
                  <DropdownSelectBox
                    options={assetOptions}
                    selectedOption={selectedAsset}
                    onChange={handleOptionChange}
                  />
                </div>
              </div>
            </>
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <div className="block-item-row">
            <Button onClick={updateTask} text="Save" widthPixel={80} />
            <LinkButton onClick={() => navigate('/tasks')} text="Cancel" />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default TaskDetailsPage;
