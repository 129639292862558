/* eslint-disable prettier/prettier */
import { useNavigate } from 'react-router-dom';
import IndexLayout from '../../../layouts/index/index.layout';
import InputComponent from '../../../components/Input/Input.component';
import Button from '../../../components/Button/Button.component';
import { useState } from 'react';
import { login } from '../../../services/auth/auth.service';
import './styles/login.style.css';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 22px;
  color: #1a1c1b;
`;

const ErrorWrapper = styled.div`
  width: 400px;
  height: 20px;
  margin-top: 20px;
  padding: 5px;
  background: #ff8f9a;
  border: 1px solid #a8323e;
`;

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const handleSubmitfunc = async () => {
    try {
      const user = await login(email, password);

      if (user.email) {
        navigate('/tasks');
      }
    } catch (e) {
      setError(true);
    }
  };

  const handleChildStateChange = (childState: string) => {
    setEmail(childState);
  };

  const handleChildStateChangePassword = (childState: string) => {
    setPassword(childState);
  };

  return (
    <IndexLayout>
      <div className="loginWrapper">
        <Title>Welcome to Helpdesk</Title>
        <span>Its task management dashboard</span>
        <div className="form">
          <InputComponent
            onChildStateChange={handleChildStateChange}
            type="text"
            placeholder="Email"
            width={400}
          />

          <InputComponent
            onChildStateChange={handleChildStateChangePassword}
            type="password"
            placeholder="Password"
            width={400}
          />

          <div>
            {error ? (
              <ErrorWrapper>Please use correct credentials!</ErrorWrapper>
            ) : null}
          </div>

          <div className="loginButton">
            <Button text="Sign In" onClick={handleSubmitfunc} />
          </div>

          <div className="forgotPassword">
            <a href="/password/reset">
              <span>Forgot Password?</span>
            </a>
          </div>
        </div>
      </div>
    </IndexLayout>
  );
}

export default LoginPage;
