import { TaskStatus } from '../enums/TaskStatus.enum';

export const CalculateTaskStatusColor = (status: TaskStatus): string => {
  switch (status) {
    case TaskStatus.Todo:
      return '#3734c2';
    case TaskStatus.Inprogress:
      return '#2596be';
    case TaskStatus.Completed:
      return '#05843e';
    default:
      return '#3f4b54';
  }
};
