/* eslint-disable prettier/prettier */
import './styles/infoMessage.style.css';

export enum MessageTypes {
  Info,
  Success,
  Warning,
  Error,
}

interface Props {
  text: string;
  type: MessageTypes;
}

const getClassName = (messageType: MessageTypes) => {
  switch (messageType) {
    case MessageTypes.Info: {
      return 'info-msg';
    }
    case MessageTypes.Success: {
      return 'success-msg';
    }
    case MessageTypes.Warning: {
      return 'warning-msg';
    }
    case MessageTypes.Error: {
      return 'error-msg';
    }
    default: {
      return 'info-msg';
    }
  }
};

export const InfoMessageComponent: React.FC<Props> = ({ text, type }) => (
  <>
    <div className={getClassName(type)}>{text}</div>
  </>
);
