/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';

export enum PasswordStrengthStatuses {
  WEAK = 'Weak',
  MEDIUM = 'Medium',
  STRONG = 'Strong',
}

const maxScore = 5;

const PasswordStatusHint = styled.div`
  font-size: 14px;
  margin-top: 3px;
`;

const PasswordStrengthItems = styled.ul`
  font-size: 12px;
  margin-top: 5px;
  padding: 0;
  padding-left: 10px;
`;

const PasswordStrengthItem = styled.li`
  display: list-item;
  list-style-type: disc;
  padding: 0;
  margin: 0;
  margin-top: 3px;
`;

const ProgressBar = styled.div`
  width: 264px;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 15px;
  margin-top: 5px;
  margin-left: -3px;
`;

const Progress = styled.div<{
  status: PasswordStrengthStatuses;
  score: number;
}>`
  height: 100%;
  background-color: ${p =>
    p.status === PasswordStrengthStatuses.WEAK
      ? '#cc0000'
      : p.status === PasswordStrengthStatuses.MEDIUM
        ? '#cfa42f'
        : '#4caf50'};
  width: ${p => (p.score * 100) / maxScore}%;
  border-radius: 15px;
`;

interface Props {
  password: string;
  onScoringFinished: (status: PasswordStrengthStatuses) => void;
}

const PasswordStrengthComponent: React.FunctionComponent<Props> = ({
  password,
  onScoringFinished,
}) => {
  const [status, setStatus] = React.useState<PasswordStrengthStatuses>(
    PasswordStrengthStatuses.WEAK,
  );
  const [score, setScore] = React.useState(0);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);

  React.useEffect(() => {
    async function getScore(passwordValue: string) {
      GetPasswordStrength(passwordValue);
      onScoringFinished(status);
    }
    getScore(password);
  });

  const GetStrengthStatus = (score: number): PasswordStrengthStatuses => {
    switch (score) {
      case 0:
      case 1:
      case 2:
        return PasswordStrengthStatuses.WEAK;
      case 3:
      case 4:
        return PasswordStrengthStatuses.MEDIUM;
      case 5:
        return PasswordStrengthStatuses.STRONG;
      default:
        return PasswordStrengthStatuses.WEAK;
    }
  };

  const GetPasswordStrength = (password: string) => {
    let score = 0;
    let messages = [];
    if (!password) return;

    // Check password length
    if (password.length >= 8) score += 1;
    else messages.push('Minimum number of characters is 8.');
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    else messages.push('Should contain lowercase.');
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    else messages.push('Should contain uppercase.');
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    else messages.push('Should contain numbers.');
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;
    else messages.push('Should contain special characters.');

    setStatus(GetStrengthStatus(score));
    setScore(score);
    setErrorMessages(messages);
  };

  return (
    <>
      <ProgressBar>
        <Progress score={score} status={status} />
      </ProgressBar>
      <PasswordStatusHint>Password is {status.toString()} </PasswordStatusHint>
      {status !== PasswordStrengthStatuses.STRONG ? (
        <>
          <PasswordStrengthItems>
            {errorMessages.map(item => {
              return (
                <PasswordStrengthItem key={item}>{item}</PasswordStrengthItem>
              );
            })}
          </PasswordStrengthItems>{' '}
        </>
      ) : null}
    </>
  );
};

export default PasswordStrengthComponent;
