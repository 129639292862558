import React from 'react';
import { useState } from 'react';
import ITask from '../../../types/task/task.type';
import DashboardLayout from '../../../layouts/dashboard/dashboard.layout';
import TaskListFeature from '../../../features/tasks/task-list.feature';
import { TaskStatus } from '../../../features/tasks/enums/TaskStatus.enum';
import { getTasks } from '../../../services/task/task.service';

function TasksdPage() {
  const [tasks, setTasks] = useState<ITask[]>(() => []);

  const inprogressTasks = tasks?.filter(
    item => item.taskStatus === TaskStatus.Inprogress,
  );
  const todoTasks = tasks?.filter(item => item.taskStatus === TaskStatus.Todo);
  const completedTasks = tasks?.filter(
    item => item.taskStatus === TaskStatus.Completed,
  );

  React.useEffect(() => {
    const fetchTasks = async () => {
      const t = await getTasks();
      setTasks(t);
    };
    fetchTasks();
  }, []);
  return (
    <DashboardLayout>
      <div className="task-wrap">
        {inprogressTasks.length > 0 ? (
          <TaskListFeature
            title="In Progress Tasks"
            showTitle={true}
            items={inprogressTasks}
          />
        ) : null}

        {todoTasks.length > 0 ? (
          <TaskListFeature
            title="Todo Tasks"
            showTitle={true}
            items={todoTasks}
          />
        ) : null}

        {completedTasks.length > 0 ? (
          <TaskListFeature
            title="Completed Tasks"
            showTitle={true}
            items={completedTasks}
          />
        ) : null}

        {inprogressTasks.length === 0 &&
        todoTasks.length === 0 &&
        completedTasks.length === 0 ? (
          <h2>There is no any tasks assigned to you</h2>
        ) : null}
      </div>
    </DashboardLayout>
  );
}

export default TasksdPage;
