/* eslint-disable prettier/prettier */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CalculateTaskStatusColor } from './helpers/CalculateTaskStatusColor';
import Task from '../../types/task/task.type';
import {
  ActionType,
  ActionTypeList,
  getReadableActionType,
} from '../../utils/action-types.utils';
import { transformTaskStatus } from '@rocket-desert/common-lib';

const ProcessPercentage = styled.div<{ color?: string }>`
  width: 100px;
  padding: 5px;
  border-radius: 5px;
  background-color: ${props => props.color};
  text-align: center;
  font-size: 12px;
  color: #fff;
`;

const Attention = styled.div`
  min-width: 100px;
  max-width: 200px;
  text-align: center;
  background-color: red;
  color: #ffffff;
  padding: 5px;
`;

const AssetAssigned = styled.div`
  min-width: 100px;
  max-width: 200px;
  text-align: center;
  background-color: #46a34b;
  color: #ffffff;
  padding: 5px;
`;

const AttentionWrapper = styled.div`
  display: flex;
  max-width: 200px;
  flex-direction: column;
  min-width: 100px;
`;

const AttentionText = styled.div`
  font-size: 10px;
  min-width: 100px;
  max-width: 200px;
  color: #06122c;
  text-align: center;
`;

const FirstTd = styled.td`
  width: 500px;
`;

const FixedTd = styled.td`
  max-width: 20%;
`;

const OnboardingTableIteam: React.FunctionComponent<Task> = task => {
  const navigate = useNavigate();
  const handleNavgatefunc = async (id: number) => {
    navigate('/task/details/' + id);
  };

  return (
    <tr key={task.id} onClick={() => handleNavgatefunc(task.id)}>
      <FirstTd>{task.title}</FirstTd>
      <FixedTd>
        {task.employee ? task.employee.firstName : null}{' '}
        {task.employee ? task.employee.lastName : null}
      </FixedTd>
      <FixedTd>
        {task.actionType === ActionTypeList.HARDWARE_SOFTWARE ? (
          <AttentionWrapper>
            {task.asset ? (
              <AssetAssigned>
                {getReadableActionType(task.actionType as ActionType)}
              </AssetAssigned>
            ) : (
              <Attention>
                {getReadableActionType(task.actionType as ActionType)}
              </Attention>
            )}
            {task.asset ? (
              <AttentionText>Asset is assigned</AttentionText>
            ) : (
              <AttentionText>Need to assign asset</AttentionText>
            )}
          </AttentionWrapper>
        ) : (
          getReadableActionType(task.actionType as ActionType)
        )}
      </FixedTd>
      <FixedTd>
        <ProcessPercentage color={CalculateTaskStatusColor(task.taskStatus)}>
          {transformTaskStatus(task.taskStatus)}
        </ProcessPercentage>
      </FixedTd>
      <FixedTd> {task.dueDate} </FixedTd>
    </tr>
  );
};

export default OnboardingTableIteam;
