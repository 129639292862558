export type ActionType =
  | 'HARDWARE_SOFTWARE'
  | 'ACCOUNT'
  | 'DOCUMENTATION_TRAINING'
  | 'ACCESS_SECURITY'
  | 'HR_PAYROLL'
  | 'COMMUNICATION_COLLABORATION'
  | 'COMPANY_POLICY_CULTURE'
  | 'FACILITIES_WORKSPACE'
  | 'IT_SUPPORT_HELPDESK'
  | 'BENEFITS_PERKS'
  | 'LEGAL_COMPLIANCE'
  | 'FEEDBACK_SURVEYS'
  | 'RESOURCE_ACCESS'
  | 'PERSONALIZED_ONBOARDING'
  | 'OTHER';

export enum ActionTypeList {
  HARDWARE_SOFTWARE = 'HARDWARE_SOFTWARE',
  ACCOUNT = 'ACCOUNT',
  DOCUMENTATION_TRAINING = 'DOCUMENTATION_TRAINING',
  ACCESS_SECURITY = 'ACCESS_SECURITY',
  HR_PAYROLL = 'HR_PAYROLL',
  COMMUNICATION_COLLABORATION = 'COMMUNICATION_COLLABORATION',
  COMPANY_POLICY_CULTURE = 'COMPANY_POLICY_CULTURE',
  FACILITIES_WORKSPACE = 'FACILITIES_WORKSPACE',
  IT_SUPPORT_HELPDESK = 'IT_SUPPORT_HELPDESK',
  BENEFITS_PERKS = 'BENEFITS_PERKS',
  LEGAL_COMPLIANCE = 'LEGAL_COMPLIANCE',
  FEEDBACK_SURVEYS = 'FEEDBACK_SURVEYS',
  RESOURCE_ACCESS = 'RESOURCE_ACCESS',
  PERSONALIZED_ONBOARDING = 'PERSONALIZED_ONBOARDING',
  OTHER = 'OTHER',
}
interface ActionTypeMap {
  [key: string]: string;
}

const actionTypeMap: ActionTypeMap = {
  HARDWARE_SOFTWARE: 'Hardware Software',
  ACCOUNT: 'Account',
  DOCUMENTATION_TRAINING: 'Documentation Training',
  ACCESS_SECURITY: 'Access Security',
  HR_PAYROLL: 'Hr Payroll',
  COMMUNICATION_COLLABORATION: 'Communication Collaboration',
  COMPANY_POLICY_CULTURE: 'Company Policy Culture',
  FACILITIES_WORKSPACE: 'Facilities Workspace',
  IT_SUPPORT_HELPDESK: 'It Support Helpdesk',
  BENEFITS_PERKS: 'Benefits Perks',
  LEGAL_COMPLIANCE: 'Legal Compliance',
  FEEDBACK_SURVEYS: 'Feedback Surveys',
  RESOURCE_ACCESS: 'Resource Access',
  PERSONALIZED_ONBOARDING: 'Personalized Onboarding',
  OTHER: 'Other',
};

export const getReadableActionType = (type: ActionType): string => {
  return actionTypeMap[type] || 'Unknown Type';
};
