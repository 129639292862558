import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';

const InputForm = styled.div`
  margin-top: 20px;
`;
const InputLabel = styled.div`
  color: #515251;
`;

const InputWrapper = styled.div``;

const InputText = styled.input<InputTextProps>`
  width: ${props => (props.width ? props.width + 'px' : '100%')};
  height: 35px;
  font-size: 14px;
  border: 1px solid #ebebeb;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;

interface InputTextProps {
  label?: string;
  type: string;
  placeholder?: string;
  width?: number;
  onChildStateChange?: any;
}

const InputTextComponent: React.FC<InputTextProps> = ({
  label,
  type,
  placeholder,
  width,
  onChildStateChange,
}) => {
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    onChildStateChange(value);
    console.log(inputValue);
  };
  return (
    <>
      <InputForm>
        {label ? <InputLabel>{label}</InputLabel> : null}
        <InputWrapper>
          <InputText
            label={label}
            width={width}
            type={type}
            placeholder={placeholder}
            onChange={handleInputChange}
          />
        </InputWrapper>
      </InputForm>
    </>
  );
};

export default InputTextComponent;
