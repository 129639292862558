import * as React from 'react';
import Task from '../../types/task/task.type';
import TaskItemFeature from './task-item.feature';
import './styles/task.style.css';
import { convertDate } from '../../utils/date-format.utility';

interface Props {
  title?: string;
  items?: Task[] | null;
  showTitle?: boolean;
  showSearch?: boolean;
  showControls?: boolean;
}

const TaskList: React.FunctionComponent<Props> = ({
  title,
  items,
  showTitle,
  showControls,
  showSearch,
}) => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          {showTitle ? <h4>{title}</h4> : null}
          {showSearch ? <div>search...</div> : null}
          {title && showControls ? <div>create button</div> : null}
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th>Title</th>
                  <th>Employee</th>
                  <th>Task Type</th>
                  <th>Status</th>
                  <th>Due Date</th>
                </tr>
                {items?.length
                  ? items.map(item => {
                      return (
                        <TaskItemFeature
                          id={item.id}
                          taskType={item.taskType}
                          taskStatus={item.taskStatus}
                          assignee={item.assignee}
                          actionType={item.actionType}
                          startDate={item.startDate}
                          dueDate={convertDate(item.dueDate)}
                          employee={item.employee}
                          title={item.title}
                          key={item.id}
                          asset={item.asset}
                        />
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskList;
