import styled from 'styled-components';

const CustomButton = styled.div<{
  bgColor?: string;
  txtColor?: string;
  widthPixel?: number;
}>`
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  cursor: pointer;
  background-color: ${props => (props.bgColor ? props.bgColor : 'gray')};
  color: ${props => (props.txtColor ? props.txtColor : '#ffffff')};
  justify-content: center;
  width: ${props => (props.widthPixel ? props.widthPixel + 'px' : '100%')};
`;

interface Props {
  text: string;
  bgColor?: string;
  textColor?: string;
  widthPixel?: number;
  onClick?: React.MouseEventHandler;
}

const Button: React.FC<Props> = ({
  text,
  bgColor,
  textColor,
  widthPixel,
  onClick,
}) => (
  <>
    <CustomButton
      onClick={onClick}
      bgColor={bgColor}
      txtColor={textColor}
      widthPixel={widthPixel}
    >
      {text}
    </CustomButton>
  </>
);

export default Button;
